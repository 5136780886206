import React from "react"
import { Link } from "gatsby"

import { routes } from "@/utils/routes"
import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import { StaticImage } from "gatsby-plugin-image"

type PageProps = {
  data: any
}

const ContactPreferences: React.FC<PageProps> = ({}) => (
  <Layout
    sectionOne={
      <>
        <StaticImage
          src="../../static/images/2023/03/stay-connected-header.jpg"
          className="absolute inset-0 -z-[1]"
          alt="Stay Connected"
        />
        <div className="container text-white pt-96 pb-4 flex flex-col items-center text-center">
          <h1 className="pb-2 md:pb-8 responsive-heading uppercase">
            Stay <span className="text-primary">Connected</span>
          </h1>
          <div className="pt-8 flex justify-center">
            <img src="/images/down.png" alt="Down arrow" />
          </div>
        </div>
      </>
    }
  >
    <SEO title="Stay Connected" />
    <div className="container py-14">
      <div className="flex justify-center">
        <div className="flex flex-col gap-4 max-w-[700px]">
          <h3 className="mb-4 text-3xl uppercase">
            The RBAI Foundation is the <span className="text-primary">hub for communications with past pupils</span>{" "}
            and friends of the School.</h3>
          <h3 className="mb-4 text-3xl uppercase">Please complete the form below if you
            would like to start receiving event <span className="text-primary">invitations</span> and <span className="text-primary">updates</span>{" "}from the
            Royal Belfast Academical Institution.
          </h3>
          <p>
            If you already receive communications from us, you can also use this
            form to indicate whether you are happy to be contacted by email,
            letter, and phone. If you choose to leave any box unticked, we will
            no longer be able to contact you via these means.
          </p>
          <p>
            Our Privacy Notice explains how we use alumni information.{" "}
            <Link
              className="link underline hover:no-underline"
              to={routes.privacy()}
            >
              Click here to review the Privacy Notice
            </Link>
            . You can also{" "}
            <a
              className="link underline hover:no-underline"
              href="/docs/RBAI-Foundation-subject_access_request_form.docx"
              target="_blank"
            >
              <strong>click here</strong>
            </a>{" "}
            to download a Subject Access Request Form.
          </p>
          <p>
            If you have any queries, please contact the RBAI Foundation office
            via P: 028 9024 0461 or E:{" "}
            <a
              className="link underline hover:no-underline"
              href="mailto:alumni@rbai.org.uk"
            >
              alumni@rbai.org.uk
            </a>
          </p>
          <iframe
            id="etapIframe"
            style={{ border: "none", width: "100%", height: 1860 }}
            src="https://app.etapestry.com/onlineforms/TheRoyalBelfastAcademy/contact.html"
          ></iframe>
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactPreferences
